export const fields = [

    { key: 'full_name', label: "User", _style:'min-width:100px' },
    { key: 'opd', label: "Opd ID", _style:'min-width:100px' },
    { key: "jenis_pekerjaan", label: "Jenis Pekerjaan", _style:'min-width:50px'},
    { key: 'sumber_dana',label: "Sumber Dana", _style:'min-width:100px;' },
    { key: 'nilai_pekerjaan', label: "Nilai Pekerjaan", _style:'min-width:100px;' },
    { key: 'tahun_anggaran', label: "Tahun", _style:'min-width:100px;' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
